"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const polygon_1 = require("./objects/polygon");
const rectangle_1 = require("./objects/rectangle");
const rounded_rectangle_1 = require("./objects/rounded-rectangle");
const circle_1 = require("./objects/circle");
const door_1 = require("./objects/door");
const line_1 = require("./objects/line");
const obstruction_1 = require("./objects/obstruction");
const window_1 = require("./objects/window");
const wall_gap_1 = require("./objects/wall-gap");
const hob_1 = require("./objects/hob");
const bath_1 = require("./objects/bath");
exports.default = {
    PolygonCreator: polygon_1.PolygonCreator,
    DoorCreator: door_1.DoorCreator,
    WindowCreator: window_1.WindowCreator,
    WallGapCreator: wall_gap_1.WallGapCreator,
    HobCreator: hob_1.HobCreator,
    BathCreator: bath_1.BathCreator,
    RectangleCreator: rectangle_1.RectangleCreator,
    RoundedRectangleCreator: rounded_rectangle_1.RoundedRectangleCreator,
    ObstructionCreator: obstruction_1.ObstructionCreator,
    CircleCreator: circle_1.CircleCreator,
    LineCreator: line_1.LineCreator,
};
