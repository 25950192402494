import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-2[0].rules[0].use[1]!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/sass-loader/dist/cjs.js!../node_modules/vue-loader/lib/index.js??vue-loader-options!./DrawingObjectActionComponent.vue?vue&type=style&index=0&id=4356ddc4&lang=scss&scoped=true&";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};