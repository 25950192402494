"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ClipboardFallback {
    localClipboard = "";
    constructor() {
        if (!window.isSecureContext)
            console.warn("Unable to utilise Clipboard API: the current window is not running in a secure context");
    }
    writeText(text) {
        if (window.isSecureContext)
            return navigator.clipboard.writeText(text);
        else {
            this.localClipboard = text;
            return Promise.resolve();
        }
    }
    readText() {
        if (window.isSecureContext)
            return navigator.clipboard.readText();
        else {
            return Promise.resolve(this.localClipboard);
        }
    }
}
exports.default = new ClipboardFallback();
