// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletMaxWidth": "600px",
	"mobileMaxWidth": "480px",
	"bg1": "#e4e6e7",
	"bg2": "#c9cdcf",
	"bg3": "#2c2e44",
	"text1": "#e6e6e6",
	"accent": "#ecb465",
	"xlarge": "calc(1rem * 1.4)",
	"large": "calc(1rem * 1.1)",
	"medium": "1rem",
	"small": "calc(1rem * 0.8)",
	"headerHeight": "7rem",
	"footerHeight": "2.5rem",
	"sidebarWidth": "21em",
	"borderWidth": "2px"
};
export default ___CSS_LOADER_EXPORT___;
