"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawingRenderOptions = void 0;
const _2d_vectors_1 = require("../../utils/2d-vectors");
class DrawingRenderOptions {
    _canvas;
    _canvasPosition;
    _canvasScale;
    constructor(canvas, canvasPosition, canvasScale) {
        this._canvas = canvas;
        this._canvasPosition = canvasPosition;
        this._canvasScale = canvasScale;
    }
    get canvas() {
        return this._canvas;
    }
    get canvasPosition() {
        return this._canvasPosition.clone();
    }
    get canvasScale() {
        return this._canvasScale;
    }
    canvasPointAt = (gridPos) => {
        const scale = this._canvasScale, width = this._canvas.width, height = this._canvas.height;
        return gridPos
            .minus(this.canvasPosition)
            .multiply(scale)
            .add(new _2d_vectors_1.Location2DInt(width, height).divide(2))
            .toInt();
    };
}
exports.DrawingRenderOptions = DrawingRenderOptions;
